.sidebar-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  background-color: #161616;
  height: 92vh;
  width: 5%;
}

.sidebar-div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  margin: 10px 0;
  font-size: 25px;
  cursor: pointer;
}

.logo-name {
  font-size: 15px;
  color: #cccccc;
  margin: 5px 0;
}

@media only screen and (max-width: 600px) {
  .sidebar-div {
    display: none;
  }
}
