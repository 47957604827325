.map-container {
  height: 92vh;
  width: 100%;
}

.map-div {
  flex-grow: 1;
  width: 100%;
}

.custom-geocoder {
  background-color: black !important;
}

.custom-geocoder .mapboxgl-ctrl-geocoder--input {
  background-color: black !important;
  color: white;
}

.confirm-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .confirm-buttons {
    flex-direction: column;
  }
}
