.navbar-div {
  /* text-align: center; */
  background-color: #161616;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbar-div > p {
  color: white;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  padding-left: 35px;
}

.nav-bar-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .navbar-div {
    justify-content: space-between;
  }

  .nav-bar-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 10px 0;
    font-size: 20px;
    cursor: pointer;
    padding-right: 35px;
    gap: 15px;
  }
}
