.details-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 15px;
  background-color: #161616;
  z-index: 1000;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: white;
  max-height: 50vh;
  overflow: auto;
  padding-left: 50px;
}

.vl {
  border-left: 4px solid #666;
  height: 40vh;
  align-self: center;
}

.section-heading {
  color: #eccf2d;
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;
}

span {
  color: #c1bfbf;
}

.cross-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  z-index: 10000;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross-button > div {
  margin: 0 4px;
}

.save-box-div {
  background-color: #161616;
  border: 1px solid white;
  border-radius: 4px;
  /* width: 20vw;
  height: 17vh; */
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  bottom: 20%;
  z-index: 10000;
  gap: 10px;
}

.save-box-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  color: white;
  border-bottom: 2px solid white;
}

.save-box-input {
  background-color: #000;
  color: white;
  border-radius: 4px;
  padding: 8px;
  font-size: 15px;
  width: 90%;
  border: 1px solid #161616;
}

.save-button {
  color: black;
  padding: 10px 35px;
  /* font-weight: bold; */
  cursor: pointer;
  border-radius: 4px;
  background-color: #eccf2d;
}

.confirm-location-div {
  background-color: #161616;
  border: 1px solid white;
  border-radius: 4px;
  /* width: 25vw;
  height: 25vh; */
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  bottom: 35%;
  left: 40%;
  z-index: 10000;
  gap: 10px;
}

.change-button {
  border: 1px solid #eccf2d;
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 4px;
  color: white;
}

@media only screen and (max-width: 600px) {
  .save-box-nav {
    font-size: 15px;
  }

  .details-div {
    flex-direction: column;
    padding-left: 10px;
    gap: 5px;
  }

  .vl {
    border-bottom: 2px solid #666;
    width: 100%;
  }

  .details-div-section {
    margin-bottom: 20px;
  }

  .confirm-location-div {
    left: 20%;
  }

  .save-box-div {
    left: 15%;
  }
}
