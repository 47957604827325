* {
  margin: 0;
}

.sidebar-map-div {
  height: 92vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}
