.saved-div {
  /* width: 20vw;
  max-width: 40vw; */
  padding: 10px 20px;
  background-color: #1c1c1c;
  position: absolute;
  left: 6%;
  z-index: 100;
  top: 15%;
  max-height: 40vh;
  overflow: auto;
  display: block !important;
}

.hist-tab-heading {
  color: #eccf2d;
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center;
}

.latlng-hist {
  color: #c1bfbf;
}

.hist-tab-div {
  font-size: 17px;
  cursor: pointer;
}

.single-hist-detail-div > p {
  margin: 0;
}

.single-hist-detail-div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
